import {
    reactive
} from 'vue'

export function useCompanyInfo(companyId) {
    let result = {}
    let reg = /^\/(.+?)\/(.+?)$/gi
    let companyName = reg.exec(companyId)[1]

    switch (companyName) {
        case 'hz':
            result = {
                title: '浙江恒通金属材料有限公司11111',
                themeColor: 'red'
            }
            break;

        case 'sy':
            result = {
                title: '浙江恒通金属材料有限公司22222',
                themeColor: 'green'
            }
            break
    }

    return result

}